import Vapor from 'laravel-vapor'
import { configApiHeaders } from '@tenant/utils/helper'

export const useUploadStorage = () => {
    const uploadStorage = (file) => {
        return Vapor.store(file, {
            baseUrl: import.meta.env.VITE_API_URL,
            headers: configApiHeaders(),
        }).then((response) => ({
            file_name: file.name,
            file_mime: file.type,
            ...response,
        }))
    }

    const uploadMultiples = async (uploadFiles = []) => {
        const uploadKeys = []

        for (const file of uploadFiles || []) {
            const { key, file_name, file_mime } = await uploadStorage(file)
            uploadKeys.push({ key, file_name, file_mime })
        }

        return uploadKeys
    }

    return { uploadStorage, uploadMultiples }
}
